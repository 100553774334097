import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'addEditBillingPlan',
  props: ['projectTypeStatus'],
  components: { ModelSelect, DatePicker },
  data() {
    return {
      unsubscribe: null,
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      setTimeVsetCode: null,
      vsetCode: null,
      showValueSetModal: null,
      switchTab: false,
      legalEntityList: [],
      billingForm: {
        billing_plan_hdr_id: 0,
        billing_plan_name: null,
        billing_plan_description: null,
        billing_start_date: null,
        billing_end_date: null,
        le_name: null,
        le_id: null,
        active: true,
        plan_status: 'BPINCOMPLETE',
        plan_status_meaning: 'Incomplete',
        plan_unit_type_vset: null,
        plan_unit_type_meaning: null,
        billing_cycle_hdr_id: null,
        billing_cycle_name: null,
        is_post_billing: false,
        is_default_plan: false,
        bill_date_ref_date: null,
        bill_date_ref_date_vset: null,
        bill_gen_ref_date_vset: null,
        bill_gen_date_ref_period: null,
        bill_gen_ref_date: null,
        bill_date_ref_period: null
      },
      selectedLegalEntity: {
        name: null,
        id: null
      }
    };
  },
  validations: {
    billingForm: {
      billing_plan_name: {
        required
      }
    }
  },
  mounted() {
    if (this.$route.params.billingPlanId) {
      this.getProjectList();
      this.getBillingPlanDetail();
    }
    if (this.$route.params.salesAgreementData) {
      this.$route.params.billingPlanId = this.$route.params.salesAgreementData.billing_plan_id;
      this.getProjectList();
      this.getBillingPlanDetail();
    }

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditBillingPlan();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', {
            id: this.billingForm.billing_cycle_hdr_id
          });
        }
      }
    });
  },
  methods: {
    getProjectList() {
      this.loader = true;
      this.$store
        .dispatch(
          'masterData/getBillingPlanProjList',
          this.$route.params.billingPlanId
        )
        .then(response => {
          this.editMode = false;
          this.loader = false;
          if (
            response.status === 200 &&
            response.data.data.billing_plan_project_details.length > 0
          ) {
            this.projectTypeStatus(
              response.data.data.billing_plan_project_details
            );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getBillingPlanDetail() {
      this.loader = true;
      this.$store
        .dispatch(
          'masterData/getBillingPlanDetail',
          this.$route.params.billingPlanId
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.billingForm = response.data.data;
            this.$emit('setPlanType', this.billingForm.plan_unit_type_vset);
            this.$store.commit(
              'masterData/setPlanStatus',
              this.billingForm.plan_status
            );
            this.$store.commit('masterData/dateData', this.billingForm);
          }
          if (this.billingForm.billing_plan_hdr_id > 0) {
            this.editMode = false;
          }
          this.checkBillingPlanCreated();
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditBillingPlan() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          billing_plan_hdr_id: this.billingForm.billing_plan_hdr_id,
          billing_plan_name: this.billingForm.billing_plan_name,
          billing_plan_description: this.billingForm.billing_plan_description,
          bill_date_ref_date_vset: this.billingForm.bill_date_ref_date_vset,
          bill_date_ref_period: this.billingForm.bill_date_ref_period,
          bill_gen_date_ref_period: this.billingForm.bill_gen_date_ref_period,
          bill_gen_ref_date_vset: this.billingForm.bill_gen_ref_date_vset,
          billing_cycle_hdr_id: this.billingForm.billing_cycle_hdr_id,
          billing_start_date: this.billingForm.billing_start_date,
          billing_end_date: this.billingForm.billing_end_date,
          is_post_billing: this.billingForm.is_post_billing,
          is_default_plan: this.billingForm.is_default_plan,
          plan_status: this.billingForm.plan_status,
          plan_unit_type_vset: this.billingForm.plan_unit_type_vset,
          active: this.billingForm.active
        };
        this.loader = true;
        this.$store
          .dispatch('masterData/addEditBillingPlan', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.billingForm = response.data.data;
              this.$route.params.billingPlanId = this.billingForm.billing_plan_hdr_id;
              this.getBillingPlanDetail();
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    checkBillingPlanCreated() {
      let billingPlanInfo = {
        tab: 'billingPlan',
        billing_plan_hdr_id: this.billingForm.billing_plan_hdr_id,
        planStatus: this.billingForm.plan_status
      };
      this.$emit('billingPlanTab', billingPlanInfo);
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === 'FMS_PENALTY_TERM') {
        this.vsetCode = 'FMS_PENALTY_TERM';
      } else {
        this.vsetCode = vsetCode;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName:
            vsetCode === 'FMS_PENALTY_TERM' ? 'FMS_PAYMENT_TERM' : vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity.value = item.org_id;
        this.selectedLegalEntity.text = item.org_name;
      } else if (this.vsetCode === 'BILL_DATE_REF_DATE') {
        this.billingForm.bill_date_ref_date = item.value_meaning;
        this.billingForm.bill_date_ref_date_vset = item.value_code;
      } else if (this.vsetCode === 'BILL_GEN_DATE_REF') {
        this.billingForm.bill_gen_ref_date_vset = item.value_code;
        this.billingForm.bill_gen_ref_date = item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE) {
        this.billingForm.billing_cycle_name = item.billing_cycle_name;
        this.billingForm.billing_cycle_hdr_id = item.billing_cycle_hdr_id;
      } else if (this.vsetCode === 'PLAN_UNIT_TYPE') {
        this.billingForm.plan_unit_type_vset = item.value_code;
        this.billingForm.plan_unit_type_meaning = item.value_meaning;
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity.value = null;
        this.selectedLegalEntity.text = null;
      } else if (vsetCode === 'BILL_DATE_REF_DATE') {
        this.billingForm.bill_date_ref_date = null;
        this.billingForm.bill_date_ref_date_vset = null;
      } else if (vsetCode === 'BILL_GEN_DATE_REF') {
        this.billingForm.bill_gen_ref_date_vset = null;
        this.billingForm.bill_gen_ref_date = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE) {
        this.billingForm.billing_cycle_name = null;
        this.billingForm.billing_cycle_hdr_id = null;
      } else if (vsetCode === 'PLAN_UNIT_TYPE') {
        this.billingForm.plan_unit_type_vset = null;
        this.billingForm.plan_unit_type_meaning = null;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.billingForm.billing_start_date
      );
    },
    verifyStatus() {
      this.loader = true;
      const payload = {
        billingPlanHdrId: this.billingForm.billing_plan_hdr_id
      };
      let url =
        this.billingForm.plan_status === 'BPINCOMPLETE'
          ? 'masterData/billingPlanVerifyStatus'
          : 'masterData/billingPlanUnVerifyStatus';
      this.$store
        .dispatch(url, payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getBillingPlanDetail();
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
