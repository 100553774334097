import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';
import appStrings from '@/app/utility/string.utility';
import componentPricingMapping from '../componentPricingMapping';
import applicableStatus from '../applicableStatus';
export default {
  name: 'componentMapping',
  components: {
    DatePicker,
    componentPricingMapping,
    applicableStatus
  },
  data() {
    return {
      showTaxCatModal: false,
      fms_comp_group_id: null,
      compGroupIndex: null,
      showCompGroup: false,
      showValueSetModal: false,
      vsetCode: null,
      unsubscribe: null,
      editMode: false,
      loader: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      showProjMapping: false,
      billingPlanName: null,
      dateData: null,
      showDetailModal: false,
      showStatusModal: false,
      compMappingId: null,
      componentName: null,
      projMappingList: [
        {
          active: false,
          billing_plan_comp_mpng_id: 0,
          end_date: null,
          fms_comp_code: null,
          fms_comp_display_name: null,
          fms_comp_group_code: null,
          fms_comp_group_display_name: null,
          fms_comp_group_id: null,
          fms_comp_group_name: null,
          fms_comp_id: null,
          fms_comp_name: null,
          rate_basis: null,
          rate_basis_vset: null,
          rate_type: null,
          rate_type_vset: null,
          start_date: null,
          tax_cat_id: null,
          tax_cat_name: null,
          void: false
        }
      ],
      projeMappingFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'component_price_mapping'
        },
        {
          key: 'fms_comp_group_name',
          label: 'Group Name'
        },
        {
          key: 'fms_comp_display_name',
          label: 'Comp Name'
        },
        {
          key: 'rate_basis'
        },
        {
          key: 'rate_type'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'status',
          label: 'Applicable On'
        },
      ],
      plan_status: this.$store.state.masterData.planStatus,
      billPlanHdrId: this.$route.params.billingPlanId,
      compMappingDetails: null,
      parentBillingId: null
    };
  },
  mounted() {
    this.dateData = this.$store.state.masterData.dateData;
    if (this.$route.params.billingPlanId) {
      this.getComponentList();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update' && this.plan_status === 'BPINCOMPLETE') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode) {
          this.addEditComMapping();
        }
      }
    });
  },
  methods: {
    getComponentList() {
      this.loader = true;
      this.$store
        .dispatch(
          'masterData/getBillingPlanCompList',
          this.$route.params.billingPlanId
        )
        .then(response => {
          this.editMode = false;
          this.loader = false;
          if (
            response.status === 200 &&
            response.data.data.billing_plan_components.length > 0
          ) {
            this.billingPlanName = response.data.data.billing_plan_name;
            this.projMappingList = response.data.data.billing_plan_components.map(
              data => {
                data.start_date = data.start_date
                  ? new Date(data.start_date)
                  : null;
                data.end_date = data.end_date ? new Date(data.end_date) : null;
                return data;
              }
            );
            this.parentBillingId = response.data.data.parent_billing_plan_hdr_id;
          } else {
            this.projMappingList[0].start_date = new Date(
              this.dateData.billing_start_date
            );
            this.projMappingList[0].end_date = this.dateData.billing_end_date
              ? new Date(this.dateData.billing_end_date)
              : null;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditComMapping() {
      if (this.projMappingList.length > 0) {
        const prjDetail = this.projMappingList.map(data => {
          return {
            active: true,
            billing_plan_comp_mpng_id: data.billing_plan_comp_mpng_id,
            fms_comp_group_id: data.fms_comp_group_id,
            fms_comp_id: data.fms_comp_id,
            rate_basis_vset: data.rate_basis_vset,
            rate_type_vset: data.rate_type_vset,
            tax_cat_id: data.tax_cat_id,
            void: false,
            start_date: data.start_date
              ? format(new Date(data.start_date), 'dd-MMM-yyyy')
              : null,
            end_date: data.end_date
              ? format(new Date(data.end_date), 'dd-MMM-yyyy')
              : null,
            fms_prj_id: data.fms_prj_id
          };
        });
        const payload = {
          billingPlanPrjDetails: {
            billing_plan_components: prjDetail
          },
          billingPlanHdrId: this.$route.params.billingPlanId
        };
        this.loader = true;
        this.$store
          .dispatch('masterData/addEditBillingPlanComponent', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.projMappingList = response.data.data.billing_plan_components.map(
                data => {
                  data.start_date = data.start_date
                    ? new Date(data.start_date)
                    : null;
                  data.end_date = data.end_date
                    ? new Date(data.end_date)
                    : null;
                  return data;
                }
              );
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    openValueSetModal(vsetCode, index) {
      this.compGroupIndex = index;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.fms_comp_group_id = null;
      if (
        this.projMappingList[this.compGroupIndex].fms_comp_group_id &&
        vsetCode === appStrings.VALUESETTYPE.FMS_COMP_TABLE
      ) {
        this.fms_comp_group_id = this.projMappingList[
          this.compGroupIndex
        ].fms_comp_group_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'FMS_COMP_GROUP_TABLE') {
        this.projMappingList[this.compGroupIndex].fms_comp_group_display_name =
          item.fms_comp_group_display_name;
        this.projMappingList[this.compGroupIndex].fms_comp_group_code =
          item.fms_comp_group_code;
        this.projMappingList[this.compGroupIndex].fms_comp_group_id =
          item.fms_comp_group_id;
        this.projMappingList[this.compGroupIndex].fms_comp_group_name =
          item.fms_comp_group_name;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_COMP_TABLE) {
        this.projMappingList[this.compGroupIndex].fms_comp_display_name =
          item.fms_comp_display_name;
        this.projMappingList[this.compGroupIndex].fms_comp_id =
          item.fms_comp_id;
        this.projMappingList[this.compGroupIndex].fms_comp_name =
          item.fms_comp_name;
      } else if (this.vsetCode === 'RATE_TYPE_VSET') {
        this.projMappingList[this.compGroupIndex].rate_type =
          item.value_meaning;
        this.projMappingList[this.compGroupIndex].rate_type_vset =
          item.value_code;
      } else if (this.vsetCode === 'RATE_BASIS_VSET') {
        this.projMappingList[this.compGroupIndex].rate_basis =
          item.value_meaning;
        this.projMappingList[this.compGroupIndex].rate_basis_vset =
          item.value_code;
      }
    },
    clearVsetValues(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.compGroupIndex = index;
      if (this.vsetCode === 'FMS_COMP_GROUP_TABLE') {
        this.projMappingList[
          this.compGroupIndex
        ].fms_comp_group_display_name = null;
        this.projMappingList[this.compGroupIndex].fms_comp_group_code = null;
        this.projMappingList[this.compGroupIndex].fms_comp_group_id = null;
        this.projMappingList[this.compGroupIndex].fms_comp_group_name = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_COMP_TABLE) {
        this.projMappingList[this.compGroupIndex].fms_comp_display_name = null;
        this.projMappingList[this.compGroupIndex].fms_comp_id = null;
        this.projMappingList[this.compGroupIndex].fms_comp_name = null;
      } else if (this.vsetCode === 'RATE_TYPE_VSET') {
        this.projMappingList[this.compGroupIndex].rate_type = null;
        this.projMappingList[this.compGroupIndex].rate_type_vset = null;
      } else if (this.vsetCode === 'RATE_BASIS_VSET') {
        this.projMappingList[this.compGroupIndex].rate_basis = null;
        this.projMappingList[this.compGroupIndex].rate_basis_vset = null;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        const len = this.projMappingList.length - 1;
        this.projMappingList.push({
          active: false,
          billing_plan_comp_mpng_id: 0,
          end_date: this.dateData.billing_end_date
            ? new Date(this.dateData.billing_end_date)
            : null,
          fms_comp_code: null,
          fms_comp_display_name: null,
          fms_comp_group_code: null,
          fms_comp_group_display_name: null,
          fms_comp_group_id: null,
          fms_comp_group_name: null,
          fms_comp_id: null,
          fms_comp_name: null,
          rate_basis: this.projMappingList[len].rate_basis,
          rate_basis_vset: this.projMappingList[len].rate_basis_vset,
          rate_type: this.projMappingList[len].rate_type,
          rate_type_vset: this.projMappingList[len].rate_type_vset,
          start_date: new Date(this.dateData.billing_start_date),
          tax_cat_id: null,
          tax_cat_name: null,
          void: false
        });
      }
    }, removeRow(index) {
      this.projMappingList.splice(index, 1);
    },
    deleteRow(item) {
      const promt = confirm('Are you sure, you want to delete?')
      if (promt) {
        const payload = {
          p_billing_plan_comp_mpng_id: item.billing_plan_comp_mpng_id
        };
        this.loader = true;
        this.$store
          .dispatch('masterData/deleteComponent', payload)
          .then(resp => {
            this.loader = false;
            this.showAlert = true;
            if (resp.status === 200) {
              this.isSuccess = true;
              this.respMessage = 'Record Deleted Successfully';
              this.getComponentList()
            } else {
              this.isSuccess = false;
              this.respMessage = resp.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.respMessage = appStrings.autoFailedMsg;
          });
      }
    },
    showHideDetailsModal(flag, rowData) {
      if (rowData) {
        this.componentName = rowData.fms_comp_display_name.toUpperCase();
        this.compMappingDetails = rowData;
      }
      this.showDetailModal = flag;
    },
    showHideStatusModal(flag, rowData) {
      if (rowData) {
        this.compMappingId = rowData.billing_plan_comp_mpng_id;
        this.compMappingDetails = rowData;
      }
      this.showStatusModal = flag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
